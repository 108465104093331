<template>
<div>
    <modal-new-work-order ref="modalWO" inputId="modal-edit-wo" :workOrder="workOrder"/>

    <div class="container" v-if="workOrder">

        <div class="title-header">
        <div class="row px-3">
            <div class="col-sm-12">
            <h2>
                <span class="badge bg-primary">{{workOrder.code}}</span>
                {{workOrder.name}}              
            </h2>
            </div>
        </div>
        </div>

        <div class="row mt-3">
        <div class="col-md-5">
            <table class="table-info">
            <tr>
                <td>Sifra</td>
                <td>{{workOrder.code}}</td>
            </tr>
            <tr>
                <td>Naziv</td>
                <td>{{workOrder.name}}</td>
            </tr>
            <tr>
                <td>Investitor</td>
                <td>{{workOrder.investor}}</td>
            </tr>
            <template v-for="employee in workOrderEmployees">
                <tr :key="employee.id" v-if="employee.user.role == 'MANAGER_I'">
                    <td>Menadzer I reda</td>
                    <td><a href="#">{{employee.employee.user.first_name}} {{employee.employee.user.last_name}}</a></td>
                </tr>
            </template>

            <template v-for="(employee,index) in workOrderEmployees">
                <tr :key="employee.id" v-if="employee.user.role == 'MANAGER_II'">
                    <td><template v-if="index==0">Menadzer II reda</template></td>
                    <td><a href="#">{{employee.employee.user.first_name}} {{employee.employee.user.last_name}}</a></td>
                </tr>
            </template>
            

            </table>  
        </div>  

        <div class="col-md-7">
            <div class="row">
            <div class="col-md-6">
                <div class="d-grid gap-2 mb-3">
                <router-link :to="{ name: 'WorkLocations'}" type="button" class="btn btn-block btn-primary btn-lg">
                    <i class="fas fa-building fa-lg me-1" /> Radne lokacije</router-link>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="d-grid gap-2 mb-3">
                <router-link :to="{ name: 'WorkPositions'}" type="button" class="btn btn-block btn-primary btn-lg">
                    <i class="fas fa-tools fa-lg me-1" /> Radne pozicije</router-link>
                </div>
            </div>
            
            <div class="col-md-6">
                <div class="d-grid gap-2 mb-3">
                <router-link :to="{ name: 'WorkOrderEmployees'}" type="button" class="btn btn-block btn-primary btn-lg">
                    <i class="fas fa-layer-group fa-lg me-1" /> Radnici</router-link>
                </div>
            </div>
            <div v-if="user.role !== 'WORKER'" class="col-md-6">
                <div class="d-grid gap-2 mb-3">
                <router-link :to="{ name: 'JobAssignment', query:{'workOrderId': workOrderID}}" type="button" class="btn btn-block btn-primary btn-lg">
                    <i class="fas fa-hard-hat fa-lg me-1" /> Uputi radnika</router-link>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-grid gap-2 mb-3">
                <router-link :to="{ name: 'LocationPositionGrid'}" type="button" class="btn btn-block btn-primary btn-lg">
                    <i class="fas fa-table fa-lg me-1" /> Tabelarni prikaz</router-link>
                </div>
            </div>

            </div>

        </div>
        </div>    

        <!-- <info-map /> -->

        <div v-if="user.role === 'ADMIN'" class="row justify-content-end">
            <div class="col-md-3">
                <div class="row">

                <div class="col-md-12">
                    <div class="d-grid gap-2 mb-3">
                    <button type="button" class="btn btn-block btn-primary btn-lg" @click="openModal">
                        <i class="fas fa-edit fa-lg me-1" /> Izmeni RN</button>
                    </div>
                </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapState} from 'vuex'
import ModalNewWorkOrder from '@/components/modals/ModalNewWorkOrder.vue';

// import InfoMap from '../maps/InfoMap.vue';
import { Modal } from "bootstrap";

export default {
    components: { ModalNewWorkOrder },

    data: function () {
        return {
            count: 0,
            modalWorkOrder: null,
        }
    },
    computed: {
        ...mapState([
            'workOrder',
            'workOrderStatus',
            'workOrderMessage',
            'workOrderEmployees',
            'user'
        ]),
        workOrderID() {
            return parseInt(this.$route.params.woId);
        }
    },

    methods: {
        loadWorkOrder(id) {
            this.$store.dispatch('getWorkOrder', {id: id});
        },

        loadWorkOrderEmployees(id){
            this.$store.dispatch('loadWorkOrderEmployees', {workOrderId: id});
        },

        openModal() {
            this.modalWorkOrder.show();
            this.$refs.modalWO.modalShow();
        },
    },

    created() {
        this.loadWorkOrder(this.workOrderID);
        this.loadWorkOrderEmployees(this.workOrderID);
    },

    mounted() {
        this.modalWorkOrder = new Modal(document.getElementById('modal-edit-wo'));
    }

  // breadcrumb () {
  //   return {
  //     label: "label",
  //     parent: "Home"
  //   }
  // },
}
</script>

<style scoped>
    .container {
        min-height: 74vh;
    }

    .table-info {
        font-size: 1.2em;
    }
    .table-info td {
        padding: 5px 10px;
    }

    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }

    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .title-header .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }

    .title-header .btn:hover, .title-header .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn .btn-title {
        margin-top: 20px;
    }


</style>