<template>
  <div class="workorder-page">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkOrder/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import WorkOrder from '@/components/work-orders/WorkOrder.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    WorkOrder,
    Footer
  }
}
</script>
